var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "picture-capture" }, [
    _c("canvas", {
      directives: [
        { name: "show", rawName: "v-show", value: false, expression: "false" }
      ],
      ref: "canvas"
    }),
    _c("div", { staticClass: "picture-capture__inner" }, [
      _c(
        "div",
        { staticClass: "picture-capture__media-container" },
        [
          _c("Media", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.videoVisible,
                expression: "videoVisible"
              }
            ],
            ref: "video",
            staticClass: "picture-capture__media",
            attrs: {
              "src-object": _vm.localTrack
                ? _vm.localTrack._dummyEl.srcObject
                : null,
              kind: "video"
            }
          }),
          _c("img", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.videoVisible === false,
                expression: "videoVisible === false"
              }
            ],
            staticClass: "picture-capture__media",
            attrs: { src: _vm.imageSrc }
          }),
          _vm.countdownVisible
            ? _c("span", { staticClass: "picture-capture__countdown" }, [
                _vm._v(_vm._s(_vm.countdown))
              ])
            : _vm._e()
        ],
        1
      ),
      _vm.hintVisible
        ? _c("p", { staticClass: "mb-0" }, [_vm._v("click when you are ready")])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "picture-capture__actions" },
        [
          _vm.triggerButtonVisible
            ? _c(
                "v-btn",
                {
                  attrs: {
                    fab: "",
                    dark: "",
                    small: "",
                    depressed: "",
                    color: "red"
                  },
                  on: { click: _vm.takePictureHandler }
                },
                [
                  _c("v-icon", { attrs: { dark: "" } }, [
                    _vm._v("photo_camera")
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm.imageSrc !== "" && _vm.submitted === false
            ? _c(
                "v-btn",
                {
                  attrs: { dark: "", depressed: "", color: "green" },
                  on: { click: _vm.onSubmit }
                },
                [_vm._v("Submit")]
              )
            : _vm._e()
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }