<template>
  <div class="picture-capture">
    <canvas v-show="false" ref="canvas" />
    <div class="picture-capture__inner">
      <div class="picture-capture__media-container">
        <Media
          v-show="videoVisible"
          ref="video"
          :src-object="localTrack ? localTrack._dummyEl.srcObject : null"
          kind="video"
          class="picture-capture__media"
        />
        <img
          v-show="videoVisible === false"
          :src="imageSrc"
          class="picture-capture__media"
        />
        <span v-if="countdownVisible" class="picture-capture__countdown">{{
          countdown
        }}</span>
      </div>

      <p v-if="hintVisible" class="mb-0">click when you are ready</p>

      <div class="picture-capture__actions">
        <v-btn
          v-if="triggerButtonVisible"
          fab
          dark
          small
          depressed
          color="red"
          @click="takePictureHandler"
        >
          <v-icon dark>photo_camera</v-icon>
        </v-btn>
        <v-btn
          v-if="imageSrc !== '' && submitted === false"
          dark
          depressed
          color="green"
          @click="onSubmit"
          >Submit</v-btn
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { TimeoutMixinFactory } from "../mixins/timeout"

export default {
  name: "PictureCapture",
  mixins: [TimeoutMixinFactory()],
  data() {
    return {
      file: null,
      imageSrc: "",
      takingPicture: false,
      submitted: false
    }
  },
  computed: {
    ...mapGetters("twilio", { twilioUsers: "users" }),
    localTrack() {
      return this?.twilioUsers?.[this.userID]?.videoTrack
    },
    userID() {
      return this.$store.getters.user.id
    },
    countdownVisible() {
      return this.intervalID !== null
    },
    videoVisible() {
      return this.imageSrc === ""
    },
    hintVisible() {
      return this.imageSrc === "" && this.takingPicture === false
    },
    triggerButtonVisible() {
      return this.imageSrc === "" && this.takingPicture === false
    }
  },
  methods: {
    takePictureHandler() {
      this.takingPicture = true
      this.runTimeout(this.onTakePicture)
    },
    async onTakePicture() {
      const canvas = this.$refs.canvas
      const context = canvas.getContext("2d")

      const { dimensions } = this.localTrack
      const { width, height } = dimensions

      canvas.width = width
      canvas.height = height

      context.translate(width, 0)
      context.scale(-1, 1)
      context.drawImage(this.$refs.video, 0, 0, width, height)

      const canvasToJpg = canvas =>
        new Promise(resolve =>
          canvas.toBlob(blob => resolve(blob), "image/jpeg", 0.8)
        )

      this.file = await canvasToJpg(canvas)
      this.imageSrc = URL.createObjectURL(this.file)

      this.takingPicture = false
    },
    onSubmit() {
      this.$emit("sendFile", this.file)
      this.submitted = true
    }
  }
}
</script>

<style lang="scss">
$picture-capture-max-height: 176px;
$picture-capture-countdown-offset: 8px;

.picture-capture {
  &__inner {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 32px 16px 0;
  }

  &__media-container {
    position: relative;
    box-shadow: 0 0 0 $default_border_width #4a3e2f;
    border-radius: $default_border-radius;
    background-color: #4a3e2f;
    margin: {
      right: auto;
      left: auto;
      bottom: 4px;
    }
  }

  &__media {
    max: {
      width: 100%;
      height: $picture-capture-max-height;
    }
    border-radius: inherit;
    vertical-align: middle;
  }

  &__countdown {
    position: absolute;
    right: $picture-capture-countdown-offset;
    bottom: $picture-capture-countdown-offset;
    line-height: 1;
    font: {
      size: 64px;
      weight: 400;
    }
    color: $color_white;
  }

  &__actions {
    margin-top: auto;
  }
}
</style>
